import { Coordinates } from '@bufteam/cfacorp_delivery.bufbuild_es/cfa/delivery/core/v1/coordinates_pb';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DeliveryArea } from '@bufteam/cfacorp_delivery.bufbuild_es/cfa/delivery/area/v1/area_service_pb';
import { getLatLng, getOuterBounds } from '../containers/DeliveryArea/utils';
import { colors } from '../theme';
import useIsDeliveryAdmin from './useIsDeliveryAdmin';

export const useMaxArea = ({
  map,
  maxArea,
}: {
  map: google.maps.Map | null;
  maxArea: DeliveryArea | undefined;
}): {
  maxAreaCoordinates?: Coordinates[];
  resetMaxArea: () => void;
  hasChanges: boolean;
} => {
  const { isDeliveryAdmin } = useIsDeliveryAdmin();
  const maxPolygon = useRef(
    new google.maps.Polygon({
      paths: [getLatLng([]), getOuterBounds()],
      fillColor: colors.gray7,
      fillOpacity: 0.5,
      strokeWeight: 0,
    }),
  );
  const [isListening, setIsListening] = useState<boolean>(false);
  const [maxAreaCoordinates, setMaxAreaCoordinates] = useState<Coordinates[]>(
    [],
  );

  /**
   * Syncs "render" state with our own use state to properly trigger
   * reactive dependency effects. This callback needs to be used every
   * time a change is made to the cooridnates of the max polygon.
   */
  const syncMaxCooridinates = () => {
    setMaxAreaCoordinates(
      maxPolygon.current
        .getPath()
        .getArray()
        .map(
          ll =>
            new Coordinates({
              longitude: ll.lng(),
              latitude: ll.lat(),
            }),
        ),
    );
  };

  /**
   * Resets polygon path back to cooridnates from maxArea/server side state
   * */
  const resetPath = useCallback(() => {
    maxPolygon.current.setPath(getLatLng(maxArea?.coordinates || []));
    syncMaxCooridinates();
  }, [maxArea?.coordinates]);

  useEffect(() => {
    resetPath();
  }, [resetPath]);

  useEffect(() => {
    maxPolygon.current.setMap(map);
  }, [map]);

  const addPolygonListeners = useCallback(() => {
    if (
      maxPolygon.current.getPath()?.getArray()?.length &&
      !isListening &&
      isDeliveryAdmin
    ) {
      setIsListening(true);
      const path = maxPolygon.current.getPath();
      // TODO(afinch7): remove old listeners?
      google.maps.event.addListener(path, 'insert_at', () => {
        syncMaxCooridinates();
      });
      google.maps.event.addListener(path, 'set_at', () => {
        syncMaxCooridinates();
      });
    }
  }, [maxPolygon, isListening, isDeliveryAdmin]);

  useEffect(() => addPolygonListeners(), [addPolygonListeners]);

  // if user is an admin, then set as editable
  // not editable if user not an admin
  useEffect(
    () => maxPolygon.current.setEditable(isDeliveryAdmin),
    [isDeliveryAdmin, maxPolygon],
  );

  const hasChanges = useMemo(() => {
    if (maxArea?.coordinates.length === maxAreaCoordinates.length) {
      return maxArea.coordinates.some(
        (dac, ci) =>
          maxAreaCoordinates[ci].latitude !== dac.latitude &&
          maxAreaCoordinates[ci].longitude !== dac.longitude,
      );
    }
    return true;
  }, [maxArea?.coordinates, maxAreaCoordinates]);

  const resetMaxArea = useCallback(() => {
    resetPath();
  }, [resetPath]);

  return {
    maxAreaCoordinates,
    resetMaxArea,
    hasChanges,
  };
};
