import { Coordinates } from '@bufteam/cfacorp_delivery.bufbuild_es/cfa/delivery/core/v1/coordinates_pb';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { booleanPointInPolygon, point } from '@turf/turf';
import differenceWith from 'lodash.differencewith';
import { DeliveryArea } from '@bufteam/cfacorp_delivery.bufbuild_es/cfa/delivery/area/v1/area_service_pb';
import { createRoot } from 'react-dom/client';
import { Button } from '@cfa/react-components';
import { useTranslation } from 'react-i18next';
import { getLatLng } from '../containers/DeliveryArea/utils';
import { colors } from '../theme';
import { getTurfPolygon } from './utils';

/**
 * This is the most vital piece of the entire repo
 * If there is a bug with any of the points or something is not working as expecting, then check:
 *
 * - set_at and insert_at events first in handlePointEvents:
 *   -- console.log() to see handlePointEvents is called
 *   -- if not, then map has lost reference to draftPolygon's event and will need to be reset
 *
 * - if you introduced a useCallback or useMemo:
 *   -- try to use without, caching tends to cause conflicts with Google Maps
 *
 * - not seeing anything happen:
 *   -- check that all of these have values: map, draftCoordinates, maxAreaCoordinates, draftPolygon
 *
 * - seeing multiple blue polygons:
 *   -- check the if statement in the useEffect calling (queryCurrent(), queryDraft())
 *   -- should check for: map && !draftCoordinates?.length && maxAreaCoordinates?.length
 *   -- likely one of these got removed or their value is not updating
 *
 *   NOTES:
 *   - any time draftCoordinates is [], the queries will be called and the polygon will be removed and recreated
 **/
export const useDraftArea = ({
  maxAreaCoordinates,
  map,
  draftArea,
}: {
  maxAreaCoordinates?: Coordinates[];
  map: google.maps.Map | null;
  draftArea: DeliveryArea | undefined;
}) => {
  const { t } = useTranslation();

  const draftPolygon = useRef<google.maps.Polygon>(
    new google.maps.Polygon({
      paths: getLatLng([]),
      strokeColor: colors.blueBorder,
      fillColor: colors.blueFill,
      strokeOpacity: 0.8,
      fillOpacity: 0.4,
      clickable: true,
      strokeWeight: 2,
      editable: true,
    }),
  );
  const [draftCoordinates, setDraftCoordinates] = useState<Coordinates[]>([]);

  /**
   * Syncs "render" state with our own use state to properly trigger
   * reactive dependency effects. This callback needs to be used every
   * time a change is made to the cooridnates of the draft polygon.
   * */
  const syncDraftCoordinates = () => {
    setDraftCoordinates(
      draftPolygon.current
        .getPath()
        .getArray()
        .map(
          ll =>
            new Coordinates({
              longitude: ll.lng(),
              latitude: ll.lat(),
            }),
        ),
    );
  };

  /**
   * Resets polygon path back to cooridnates from draftArea/server side state
   * */
  const resetPath = useCallback(() => {
    draftPolygon.current.setPath(getLatLng(draftArea?.coordinates || []));
    syncDraftCoordinates();
  }, [draftArea?.coordinates]);

  // I'm not sure I like this use of side effect "stacking",
  // but the alternative is code duplication.
  useEffect(() => {
    resetPath();
  }, [resetPath]);

  useEffect(() => {
    draftPolygon.current.setMap(map);
  }, [map]);

  const getInfoWindowContainer = useCallback(
    (onClickHandler: () => void) => {
      const container = document.createElement('div');
      const root = createRoot(container);
      root.render(
        <div>
          <Button onClick={onClickHandler} size="sm">
            {t('DeliveryArea.deleteInfoWindow')}
          </Button>
        </div>,
      );
      return container;
    },
    [t],
  );
  const infoWindow = useMemo(() => new google.maps.InfoWindow(), []);

  const isWithinMaxArea = useCallback(
    (ll: google.maps.LatLng): boolean => {
      if (!maxAreaCoordinates?.length) {
        return false;
      }
      return booleanPointInPolygon(
        point([ll.lng(), ll.lat()]),
        getTurfPolygon(maxAreaCoordinates),
      );
    },
    [maxAreaCoordinates],
  );

  // if not called, then undo button will stay at invalid point coordinates
  const resetUndoButton = useCallback(() => {
    draftPolygon.current.setEditable(false);
    draftPolygon.current.setEditable(true);
  }, [draftPolygon]);

  // check if there is no difference between coordinates in draftCoordinates and current draftPolygon's path
  const isNoPointChange = useCallback(
    (ll?: google.maps.MVCArray<google.maps.LatLng>) =>
      !differenceWith(
        ll?.getArray().map((l: google.maps.LatLng) => ({
          latitude: l.lat(),
          longitude: l.lng(),
        })) as Coordinates[],
        draftCoordinates,
        (p, d) => p.latitude === d.latitude && p.longitude === d.longitude,
      ).length,
    [draftCoordinates],
  );

  const hasChanges = useMemo(() => {
    if (draftArea?.coordinates.length === draftCoordinates.length) {
      return draftArea.coordinates.some(
        (dac, ci) =>
          draftCoordinates[ci].latitude !== dac.latitude &&
          draftCoordinates[ci].longitude !== dac.longitude,
      );
    }
    return true;
  }, [draftArea?.coordinates, draftCoordinates]);

  const handlePointEvents = useCallback(
    (i: number, previousLatLng?: google.maps.LatLng) => {
      const path = draftPolygon.current.getPath();

      // If no changes have been made do nothing
      const isNoChange = isNoPointChange(path);
      if (!path || isNoChange) {
        return;
      }

      // If anything about the change is invalid "abort" the change
      const isValidPoint = isWithinMaxArea(path.getAt(i));
      // set_at: invalid handling
      if (!isValidPoint && previousLatLng) {
        path.setAt(i, previousLatLng);
        resetUndoButton();
        return;
      }
      // insert_at: invalid handling
      if (!isValidPoint && !previousLatLng && map) {
        path.removeAt(i);
        resetUndoButton();
        return;
      }

      // If everything checks out prevent/undo nothing and sync changes
      syncDraftCoordinates();
    },
    [isNoPointChange, isWithinMaxArea, map, resetUndoButton],
  );

  const deletePoint = useCallback(
    (e: google.maps.PolyMouseEvent) => {
      const path = draftPolygon.current.getPath();
      const i = path
        .getArray()
        .findIndex(
          (ll: google.maps.LatLng) =>
            ll.lat() === e.latLng?.lat() && ll.lng() === e.latLng.lng(),
        );
      if (i > -1) {
        path.removeAt(i);
        resetUndoButton();
      }
      syncDraftCoordinates();
      infoWindow.close();
    },
    [draftPolygon, infoWindow, resetUndoButton],
  );

  const displayDeletion = useCallback(
    (e: google.maps.PolyMouseEvent) => {
      infoWindow.setContent(getInfoWindowContainer(() => deletePoint(e)));
      infoWindow.setPosition(e.latLng);
      infoWindow.open({ map });
    },
    [deletePoint, getInfoWindowContainer, infoWindow, map],
  );

  useEffect(() => {
    google.maps.event.addListener(
      draftPolygon.current,
      'contextmenu',
      displayDeletion,
    );
    const path = draftPolygon.current.getPath();
    google.maps.event.addListener(path, 'insert_at', handlePointEvents);
    google.maps.event.addListener(path, 'set_at', handlePointEvents);
  }, [deletePoint, handlePointEvents, displayDeletion]);

  const setEstimateToDraft = (e: Coordinates[]) => {
    draftPolygon.current.setPath(getLatLng(e));
    syncDraftCoordinates();
  };

  const resetDraft = useCallback(() => {
    resetPath();
  }, [resetPath]);

  return {
    setDraftCoordinates,
    setEstimateToDraft,
    draftCoordinates,
    resetDraft,
    hasChanges,
  };
};
